import Link from "next/link";
import { FairyIcon } from "../../utils/icons";

export function Homepage() {
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="mx-auto grid max-w-4xl grid-cols-2 items-center">
        <div className="mx-auto md:w-72">{FairyIcon}</div>
        <div className="text-center">
          <h1 className="mb-5 font-bitter text-3xl font-semibold">
            On-chain raffles made <span className="text-primary">fair</span> ✨
          </h1>
          <p className="mb-12 text-sm">
            Connect your web3 wallet and create a fully on-chain, <br />
            transparent raffle with verifiable randomness.
          </p>
          <div className="space-y-2">
            <div className="mx-auto max-w-fit">
              <Link href="/create" className="button">
                Create Raffle
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
